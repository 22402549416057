<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：开单主页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 开单总框 -->
  <div class="billingAll">
    <!-- 开单左边列表 -->
    <div class="AppLeftBox">
      <ul class="ListClassfi">
        <!-- 搜索框 -->
        <li class="billingSearch">
          <el-input placeholder="搜索分组或视图"></el-input>
        </li>
        <!-- 任务分组 -->
        <li class="billingGroup">
          <span class="title">任务分组</span>
          <span class="icon">
            <i class="el-icon-collection"></i>
          </span>
        </li>
        <!-- 任务 -->
        <li class="billingTask">
          <span class="title">任务&nbsp;&nbsp;&nbsp;1/6</span>
          <span class="icon">
            <i class="el-icon-collection"></i>
          </span>
        </li>
        <!-- 视图 -->
        <li class="billingView">
          <div class="viewTitle">
            <span class="title">
              视图&nbsp;&nbsp;&nbsp;<i class="el-icon-collection"></i>
            </span>
            <span class="icon">
              <i class="el-icon-collection"></i>
            </span>
          </div>
          <ul class="viewList">
            <li>
              <span class="title">
                <i class="el-icon-collection"></i>所有任务
              </span>
              <span class="icon">...</span>
            </li>
            <li>
              <span class="title">
                <i class="el-icon-collection"></i>我执行的任务
              </span>
              <span class="icon">...</span>
            </li>
            <li>
              <span class="title">
                <i class="el-icon-collection"></i>未完成的任务
              </span>
              <span class="icon">...</span>
            </li>
            <li>
              <span class="title">
                <i class="el-icon-collection"></i>今天的任务
              </span>
              <span class="icon">...</span>
            </li>
            <li>
              <span class="title">
                <i class="el-icon-collection"></i>待领取的任务
              </span>
              <span class="icon">...</span>
            </li>
            <li>
              <span class="title">
                <i class="el-icon-collection"></i>已完成的任务
              </span>
              <span class="icon">...</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- 开单分类拉伸 -->
    <div class="AppHandelBox"></div>

    <!-- 开单操作内容 -->
    <div class="AppRightBox">
    	<router-view></router-view>
    </div>
  </div>
</template>

<script>
  // 导入组件

  export default{
    name:'billingIndex',
    data(){
      return{

      }
    },
    mounted() {
      // 初始化分类栏目拉伸
      this.JqueryCommJs.getDunamicWidth();
    },
    components:{
    }
  }
</script>

<style lang="less">
  .billingAll{
    width: 100%;
    height: 100%;
    display: flex;
    // 左边操作列表内容
    .AppLeftBox{
      // width: 200px !important;
      .ListClassfi{
        padding: 15px;
        cursor: pointer;
        li{
          margin-bottom: 10px;
          font-size: 12px;
          // border: 1px solid black;
          .title{
            width: 80%;
            // border: 1px solid black;
          }
          .icon{
            text-align: center;
            width: 20%;
          }
        }
        .billingSearch{//搜索

        }
        .billingGroup{//任务分组
          padding: 10px 5px;
          display: flex;
          justify-content: space-between;
        }
        .billingTask{//任务
          padding: 10px 5px;
          display: flex;
          background-color: #e1eefb;
          justify-content: space-between;
        }
        .billingView{//视图
          .viewTitle{//新增视图
            padding: 10px 5px;
            display: flex;
            font-weight: bold;
          }
          .viewList{//视图列表
            li{
              padding: 10px 5px;
              margin-bottom: 0;
              display: flex;
              i{
                margin-right: 10px;
              }
            }
            li:hover{
              background-color: #e1eefb;
            }
          }
        }
      }
    }
  }
</style>

<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
